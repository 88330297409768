<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row>
        <v-col
          cols="12"
          md="auto"
          class="px-0">
          <v-btn
            color="primary"
            outlined
            depressed
            :ripple="false"
            style="width: fit-content; background-color: white;"
            @click="onBack()">
            <v-icon>
              mdi-chevron-left
            </v-icon>
            <span>
              ย้อนกลับ
            </span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-row class="form-container rounded-lg secondary--text">
            <v-col cols="12">
              <h3>
                ฟอร์มกรอกข้อมูลลูกค้า
              </h3>
              <v-divider class="mt-3" />
            </v-col>
            <!-- <v-col cols="12">
              <image-uploader
                v-model="images"
                :max-file="1"
                label="รูปโปรไฟล์"
                square />
            </v-col> -->
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                เลขที่ลูกค้า
              </div>
              <v-text-field
                v-model="formData.id"
                placeholder="ALLXXXX-XXXXX"
                color="primary"
                outlined
                dense
                hide-details
                disabled
                required />
            </v-col>
            <v-col
              cols="12"
              md="6">
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                ชื่อ
              </div>
              <v-text-field
                v-model="formData.firstName"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                นามสกุล
              </div>
              <v-text-field
                v-model="formData.lastName"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                วัน เดือน ปีเกิด
              </div>
              <DatePickerDialog
                v-model="formData.birthDate"
                format="D MMMM BBBB"
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                หมายเลขโทรศัพท์
              </div>
              <v-text-field
                v-model="formData.tel"
                type="number"
                :rules="telBoxRules"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                อีเมล
              </div>
              <v-text-field
                v-model="formData.email"
                type="email"
                :rules="emailBoxRules"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
            <v-col
              v-if="!isEdit"
              cols="12"
              md="6">
              <div class="mb-1">
                รหัสผ่าน
              </div>
              <v-text-field
                v-model="formData.password"
                :type="passwordType"
                :rules="textBoxRules"
                color="primary"
                outlined
                dense
                required
                :disabled="loading"
                hide-details
                :append-icon="passwordType === 'password' ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                @click:append="togglePassword()" />
            </v-col>
            <v-col
              cols="12"
              md="6">
              <div class="mb-1">
                เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
              </div>
              <v-text-field
                v-model="formData.citizenNo"
                color="primary"
                outlined
                dense
                hide-details
                required
                :disabled="loading" />
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import MembersProvider from '@/resources/members.provider'
import DatePickerDialog from '../../../components/DatePickerDialog.vue'

const MembersService = new MembersProvider()

export default {
  components: {
    DatePickerDialog
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    emailBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    telBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    formData: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      tel: '',
      imageUrl: '',
      birthDate: '',
      citizenNo: '',
      lineUid: '',
      fbUid: '',
      googleUid: '',
      totalOrders: 0,
      points: 0,
      pointExpire: null,
      rank: 'bronze',
      channel: 'email'
    },
    passwordType: 'password',
    images: []
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    togglePassword () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    onBack () {
      this.$router.push({ name: 'MemberList' })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await MembersService.getItemById(this.$route.params.id)

        this.formData = { ...data }
        // this.images = data.imageUrl ? [data.imageUrl] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        // let imageUrl = this.images[0]?.mediaUrl || ''

        // if (this.images[0]?.file) {
        //   const { data: image } = await UploaderService.uploadFile(this.images[0].file)

        //   imageUrl = image?.public_url
        // }

        if (!this.isEdit) {
          await MembersService.create(this.formData)
        } else {
          await MembersService.updateItemById(this.formData)
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'MemberList' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
